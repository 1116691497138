export const TRANSLATIONS = {
  "en": {
    "playRandomGame": "Play a random game",
    "randomGameLoading": "Selecting ...",
    "playNowRandomGame": "Play Now",
    "noGames": "There are no random games to play"
  },
  "zh-hk": {
    "playRandomGame": "玩隨機遊戲",
    "randomGameLoading": "選擇...",
    "playNowRandomGame": "現在播放",
    "noGames": "There are no random games to play"
  },
  "de": {
    "playRandomGame": "Spiel ein Zufallsspiel",
    "randomGameLoading": "Auswahl läuft",
    "playNowRandomGame": "Jetzt spielen",
    "noGames": "There are no random games to play"
  },
  "fr": {
    "playRandomGame": "Jouer à un jeu aléatoire",
    "randomGameLoading": "Sélectinner ...",
    "playNowRandomGame": "Jouer maintenant",
    "noGames": "There are no random games to play"
  },
  "ro": {
    "playRandomGame": "Joaca-te un joc aleatoriu",
    "randomGameLoading": "Se selecteaza ...",
    "playNowRandomGame": "Joaca-te Acum",
    "noGames": "Nu exista jocuri aleatorii de jucat"
  },
  "tr": {
    "playRandomGame": "Rastgele Bir Oyun Oyna",
    "randomGameLoading": "Oyun Seçiliyor",
    "playNowRandomGame": "Şimdi Oyna",
    "noGames": "There are no random games to play"
  },
  "es": {
    "playRandomGame": "Juego aleatorio",
    "randomGameLoading": "Seleccionando…",
    "playNowRandomGame": "Juega Ya",
    "noGames": "There are no random games to play"
  },
  "pt": {
    "playRandomGame": "Jogue um jogo aleatório",
    "randomGameLoading": "Selecionando ...",
    "playNowRandomGame": "Jogue agora",
    "noGames": "There are no random games to play"
  },
  "hr": {
    "playRandomGame": "Igrajte nasumičnu igru",
    "randomGameLoading": "Odabir...",
    "playNowRandomGame": "Igraj sada",
    "noGames": "Nema nasumičnih igara za igranje"
  },
  "pt-br": {
    "playRandomGame": "Jogue um jogo aleatório",
    "randomGameCarregando": "Selecionando...",
    "playNowRandomGame": "Jogue agora",
    "noGames": "There are no random games to play"
  },
  "es-mx": {
    "playRandomGame": "Jugar un juego al azar",
    "randomGameCargando": "Seleccionando..",
    "playNowRandomGame": "Jugar ahora",
    "noGames": "There are no random games to play"
  }
}